<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <CouponForm ref="form" :isEdit="true"></CouponForm>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item" v-if="!isClientChild">
            <button class="btn btn-red" type="button" @click="showDeleteModal">
              削除
            </button>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'EventCoupon' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-main" :handle-submit="register" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          選択したクーポンを削除しますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeModal('deletedModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="deleteCoupon"
              button-text="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixins
import nav from '@/mixins/nav/coupon-event';
import modal from '@/mixins/plugin/modal';
import edit from '@/mixins/plugin/edit';
//component
import Modal from '@/components/Modal.vue';
import CouponForm from '../../Shop/Coupon/Form.vue';

export default {
  name: 'EventCouponEdit',
  data: function() {
    return {
      pageName: 'クーポン',
      deletedModal: false,
    };
  },
  components: {
    CouponForm,
    Modal
  },
  mixins: [nav, modal, edit],
  computed: {
    ...mapGetters({
      isClientChild: 'auth/isClientChild',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    id() {
      return this.$route?.params.id;
    },
  },
  methods: {
    register: function() {
      this.$refs.form.handleSubmit();
    },
    showDeleteModal() {
      this.showModal('deletedModal');
    },
    async deleteCoupon() {
      const res = await this.$store.dispatch('coupon/deleteEventCoupon', {
        subdomain: this.subdomain,
        data: {
          allFlag: false,
          id: [Number(this.id)]
        }
      });
      this.closeModal('deletedModal');
      if (res) {
        if (res.data.count > 0) {
          await this.$router.push({ name: 'EventCoupon' });
          this.$message.deleted('coupon');
        } else {
          this.$message.showError('deletedBySomeone');
        }
      }
    },
  },
};
</script>
